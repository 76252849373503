<template>
  <div v-if="!previewDialog">
    <h5 v-if="!countdownMessage" style="font-weight: 400; font-size: 13px; margin: 0;" class="wsWHITETEXT">{{$t('webinar.main.preview.before_start')}}:
      <span style="font-weight: 600">{{ timeToStart }}</span>
    </h5>
    <p v-else style="font-weight: 400; font-size: 13px; margin: 0;" class="wsWHITETEXT">{{ countdownMessage }}</p>
  </div>

  <div v-else class="d-flex align-center justify-space-between mb-4" style="column-gap: 8px" :style="`color: ${wsACCENT}`">
    <div v-for="(item, index) in previewTimeItems" :key="index" class="rounded pa-4" :style="`border: 1px solid ${wsBACKGROUND}; width: 33%`">
      <h5>{{ setTimeLabel(item.name, String(item.value)) }}</h5>
      <h4> {{ item.value }}</h4>
    </div>
  </div>
</template>

<script>
import webinars from "@/modules/webinars/mixins/webinars";
export default {
    name: 'wsCountDown.vue',
    mixins: [webinars],
    props: {
       previewDialog: {
         type: Boolean,
         default: false
       },
    },
   data() {
     return {
       countdownMessage: '',
       hour: 0,
       minute: 0,
       second: 0,
     }
   },
   computed: {
      dateStart() {
        return this.WEBINAR.date_start
      },
      timeToStart() {
       return `${this.hour}:${this.minute}:${this.second}`;
      },
      previewTimeItems() {
       return [
         { text: this.$t('webinar.main.preview.hours'), value: this.hour, name: 'hours' },
         { text: this.$t('webinar.main.preview.minutes'), value: this.minute, name: 'minutes' },
         { text: this.$t('webinar.main.preview.seconds'), value: this.second, name: 'seconds' }
       ]
      },
   },
   methods: {
     setTimeLabel(name, value) {
       const label = this.$tc(name, value).split(' ');
       if (!label.length) return '';

       const timeLabel = label[1];
       return timeLabel[0].toUpperCase() + timeLabel.slice(1);
     },

     countdown(dateStart) {
       const now = new Date().getTime();
       const webinarDate = new Date(dateStart)
       const distance = webinarDate - now

       if (distance > 24 * 60 * 60 * 1000) {
         this.hour = Math.floor(distance / (60 * 60 * 1000));
         this.minute = Math.floor((distance % (60 * 60 * 1000)) / (60 * 1000));
         this.second = Math.floor((distance % (60 * 1000)) / 1000);
       } else {
         this.hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         this.minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
         this.second = Math.floor((distance % (1000 * 60)) / 1000);
       }

       if (distance > 0) {
         setTimeout(() => {
           this.countdown(webinarDate);
           this.countdownMessage = '';
         }, 1000);
       } else {
         this.countdownMessage = this.$t('webinar.main.preview.starting');
         this.hour = '00';
         this.minute = '00';
         this.second = '00';
       }

     },
   },
   mounted() {
     this.countdown(this.dateStart);
   },
  watch: {
    dateStart(val) {
      this.countdown(val)
    }
  }
 }

</script>

<style scoped>

</style>
