<template>
  <ws-dialog
    v-model="display"
    :save-text="$t('Next')"
    :cancel-text="$t('GoBack')"
    not-centered
    :width="'448'"
    persistent
    :cancel-action="true"
    @cancel="$emit('exit-webinar')"
    @save="handleStart"

    :disable-save="!DEVICES_ACCESS_PERMITTED"
  >
    <template #header>
      <h3 class="pb-3 px-6 pt-5" :style="`color : ${wsDARKER}; font-weight: 600; font-size: ${!SM ? 20 : 16}px`">
        {{  WEBINAR.name  }}
      </h3>
    </template>

    <template #default>

      <!-- Settings and Permissions dialog  -->
      <h4 class="mb-2">{{ $t('webinar.main.preview.start_webinar_in') }}</h4>
      <!-- Time till start  -->
      <ws-count-down previewDialog />

      <!-- Video Preview and Controlls  -->
      <div
        class="d-flex align-center justify-center rounded"
        :style="`background-color: ${!(VIDEO_IS_ON && SELECTED_CAMERA) ? wsBACKGROUND : 'transparent'}; position: relative;
            padding: ${!(VIDEO_IS_ON && SELECTED_CAMERA) ? '65px 0 66px 0' : '0'}; min-height: 300px`"
      >
        <!--  Place for avatar  -->
        <v-sheet
          :style="VIDEO_IS_ON && SELECTED_CAMERA ? '' : { width: '78px', height: '78px', borderRadius: '50%', backgroundColor: wsDARKLIGHT } "
          class="d-flex align-center justify-center "
        >
          <h2 v-if="!(VIDEO_IS_ON && SELECTED_CAMERA)" :style="`font-size: 24px; color: ${wsACCENT}`">{{$t('webinar.main.preview.no_avatar')}}</h2>
          <!--  Video block (preview)   -->

          <video v-show="VIDEO_IS_ON && SELECTED_CAMERA" ref="videoWebinarPreview" autoplay style="width: 100%; height: auto" />
        </v-sheet>

        <!--   Media control panel (small dialog) -->
        <webinar-media-selector position />

      </div>


    </template>
  </ws-dialog>

</template>

<script>
import webinars from "@/modules/webinars/mixins/webinars";
import webinarMediaSelector from "@modules/webinars/components/Webinar/admin/room/UI/controls/webinarMediaSelector.vue"
import WsCountDown from "@modules/webinars/components/Webinar/admin/room/UI/header/wsCountDown.vue"


export default {
  name: "wsWebinarPreviewDialog.vue",
  mixins: [webinars],
  components : {
    WsCountDown,
    webinarMediaSelector
  },
  props: {
    value : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      display : false,
      stream : null,
      audioStream : null
    }
  },
  watch : {
    async SELECTED_MICROPHONE() {

      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.SELECTED_MICROPHONE,
        }
      });
      this.$store.state.stream.microphoneOn = true

    },
    async SELECTED_CAMERA() {

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.SELECTED_CAMERA,
          width: { ideal: 640 },
          height: { ideal: 480 }
        }
      });
      this.stream = stream;
      const videoContainer = this.$refs.videoWebinarPreview;
      videoContainer.srcObject = this.stream;
      this.$store.state.stream.videoOn = true
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    handleStart() {
      this.$store.state.stream.videoSources.push({
        value : this.SELECTED_CAMERA,
        name : this.$store.state.auth.userName,
        stream : this.stream,
        isLocal : true,
        active : true  ,
        main : true,
        deviceId : this.SELECTED_CAMERA
      })
      this.$store.state.stream.audioSources.push({
        value : this.SELECTED_MICROPHONE,
        name : this.$store.state.auth.userName,
        stream : this.audioStream,
        isLocal : true,
        active : true  ,
        main : true,
        deviceId : this.SELECTED_MICROPHONE
      })

      this.display = false
    }
  },
  async mounted() {

    if ( this.value ) {
      this.display = this.value
    }

  },
}
</script>

<style scoped lang="scss">
.v-icon, .v-icon.v-icon::after {
  background-color: transparent;
}
</style>
